export enum Consts {
  API_URL_LOCAL = "https://localhost:44368/api",
  API_URL_PRODUCTION = "https://iodemo-api.digipro.com.vn/api",
  // API_URL_PRODUCTION = "http://io-api.digipro.com.vn/api",
  API_URL_HO_CHIEU = "http://14.162.144.190:9006",
  BACKEND_PROTOCOL = "http://",
  // BACKEND_URL_LOCAL = "localhost:4200",
  BACKEND_URL_LOCAL = "https://localhost:44368/api",
  BACKEND_URL_PRODUCTION = "https://nxb-api.cnttvietnam.com.vn/",
  GET_CAPTURE_IMAGE = 'http://camerasocket.3sjsc.com:5000/api/capture_image'
}
