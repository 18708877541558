export const locale = {
  lang: "vi",
  data: {
    BUTTONS: {
      ADD: "Thêm mới",
      ADD_EMAIL: "Thêm mới Email/App",
      ADD_SMS: "Thêm mới SMS",
      EDIT: "Sửa",
      DELETE: "Xóa",
      LOCK: "Khóa/Mở khóa",
      SHOW_INACTIVE: "Hiển thị bản ghi bị khóa",
      EXPORT_EXCEL: "Xuất Excel",
      EXPORT_TEMPLATE: "Mẫu file Import",
      EXPORT_PDF: "Xuất PDF",
      IMPORT: "Nhập file",
      SAVE: "Lưu",
      CANCEL: "Hủy bỏ",
      BACK: "Quay lại",
      PRINT: "In",
      CLOSE: "Đóng",
      COPY: "Sao chép",
      CHANGE_STATUS: "Đổi trạng thái",
      RESET_NUMBER: "Đánh lại số",
      ADD_REQUEST: "Tạo yêu cầu mua hàng",
      ADD_ORDER: "Thêm đơn hàng",
      PAYMENT_ORDER: "Thanh toán đơn hàng",
      CANCEL_ORDER: "Hủy đơn hàng",
      FINISH: "Hoàn thành",
      CONFIRM: "Xác nhận",
      VIEW_DIARY: "Xem nhật ký chăm sóc",
      PREVIEW: "Xem thử",
      MENU: "Danh sách",
      PRINT_CARD: "In thẻ",
      PAY: "Thanh toán",
      ADD_PAYMENT: "Tạo giao dịch",
      RECEIVE_PROMOTION: "Nhận khuyến mãi",
      DELETE_ALL: "Xóa hoàn toàn",
      RESTORE: "Khôi phục",
      ADD_LIST: "Lập danh sách",
      VIEW: "Xem",
      NOTIFICATION: "Thông báo",
      CONDITION: "Điều kiện",
      CLEAR: "Xóa tìm kiếm",
      APPROVED: "Phê duyệt",
      ACCEPT: "Chấp nhận",
      REPLY: "Trả lời",
      REFUSE: "Từ chối",
      TRASH: "Thùng rác",
      DRAFT: "Lưu nháp",
      POST: "Đăng bài",
      CHANGE_PASS: "Đổi mật khẩu",

    },
    notify: {
      ERROR_UNDEFINED: "Lỗi không xác định!",
      SERVER_ERROR: "Lỗi server!",
      ADD_SUCCESS: "Cập nhật thành công!",
      EDIT_SUCCESS: "Cập nhật thành công!",
      DELETE_SUCCESS: "Xóa thành công!",
      ADD_ERROR: "Cập nhật không thành công!",
      EDIT_ERROR: "Cập nhật không thành công!",
      ADD_EQUIPMENT_ERROR: "Thêm mới thiết bị không thành công!",
      DELETE_ERROR: "Xóa không thành công!",
      NO_RECORD_SELECT: "Chưa bản ghi nào được chọn!",
      RESTORE_SUCCESS: "Khôi phục thành công!",
      RESTORE_ERROR: "Khôi phục không thành công!",
      ERROR_CLIENT_ID_MISSING: "Cập nhật không thành công, thông tin trạm là bắt buộc!",
      ERROR_PORT_ID_MISSING: "Cập nhật không thành công, thông tin cổng là bắt buộc!",
      ERROR_USER_LOCKED:"Tài khoản này đã bị khóa!",
      ERROR_PASSWORD_INCORRECT: "Thông tin tài khoản và mật khẩu không chính xác! Hãy kiểm tra lại ",
      ERROR_USERNAME_NOT_EXISTS:"Tài khoản này không tồn tại trong hệ thống!",
      ERROR_OLD_PASSWORD_INCORRECT:"Mật khẩu cũ không chính xác!"
    },
  },
};
